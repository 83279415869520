import { useState, useEffect } from "react";
import { GetCountries, GetState, GetCity } from "react-country-state-city";
import styled from "styled-components";
import "react-country-state-city/dist/react-country-state-city.css";

const LocationSelector = (props) => {
  const [countryid, setCountryid] = useState(39);
  const [stateid, setStateid] = useState(0);
  const [countriesList, setCountriesList] = useState([]);
  const [cityid, setCityid] = useState(0);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [showCity, setShowCity] = useState(false);
  useEffect(() => {
    setShowCity(props.showCity ? showCity : false);
    GetCountries().then((result) => {
    // Prioritize Canada (id: 39) and USA (id: 233)
    const prioritizedCountries = result.filter((country) => 
      country.id === 39 || country.id === 233
    );

      // Combine prioritized countries with the rest of the countries
      const otherCountries = result.filter((country) => 
        country.id !== 39 && country.id !== 233
      );
      
      // Set the countries list with Canada and USA first
      setCountriesList([...prioritizedCountries, ...otherCountries]);

    
      if (props.updatedCountry) {
        var country = result.find((x) => x.name === props.updatedCountry);
        if (country) setCountryid(country.id);
      }

        GetState(country ? country.id : countryid).then(
          (result, id = country ? country.id : countryid) => {
            setStateList(result);
           
            if (props.updatedProvince) {
              var state = result.find((x) => x.name === props.updatedProvince);
              if (state) {
                setStateid(state.id);
                GetCity(id, state.id).then((result) => {
                  setCityList(result);
                  if (props.updatedCity) {
                    var city = result.find((x) => x.name === props.updatedCity);
                    if (city) setCityid(city.id);
                  }
                });
              }
            }
          }
        );
     
    });
  }, [props.updatedCountry]);
  return (
    <div className="flex flex-row gap-5 justify-between">
      <div className=" flex flex-col gap-2">
        <label>
          <Labelspan>COUNTRY</Labelspan>
        </label>
        <select
          className="form-select mb-2"
          onChange={(e) => {
            const country = countriesList.find(
              (x) => x.id.toString() === e.target.value
            );
            props.setUpdatedCountry(country.name);
            setCountryid(country.id);
            GetState(country.id).then((result) => {
              setStateList(result);
            });
          }}
          value={countryid}
          disabled={props.isDisabled}
        >
          {countriesList.map((item, index) => (
            <option key={item.id} value={item.id}>
              {item.name}
            </option>
          ))}
        </select>
      </div>
      <div className="flex flex-col gap-2">
        <label>
          {" "}
          <Labelspan>PROVINCE / STATE</Labelspan>
        </label>
        <select
          className="form-select mb-2"
          onChange={(e) => {
            const state = stateList.find(
              (x) => x.id.toString() === e.target.value
            ); //here you will get full state object.
            setStateid(state.id);
            props.setUpdatedProvince(state.name);
            GetCity(countryid, state.id).then((result) => {
              setCityList(result);
            });
          }}
          value={stateid}
          disabled={props.isDisabled}
        >
          <option key={0} value={""}>
            Select Province / State
          </option>
          {stateList.map((item, index) => (
            <option key={item.id} value={item.id}>
              {item.name}
            </option>
          ))}
        </select>
      </div>
      {props.showCity && (
        <div className="flex flex-col gap-2">
          <label>
            {" "}
            <Labelspan>CITY</Labelspan>
          </label>
          <select
            className="form-select mb-2"
            onChange={(e) => {
              const city = cityList.find(
                (x) => x.id.toString() === e.target.value
              ); //here you will get full city object.
              setCityid(city.id);
              props.setUpdatedCity(city.name);
            }}
            value={cityid}
            disabled={props.isDisabled}
          >
            <option key={0} value={""}>
              Select City
            </option>
            {cityList.map((item, index) => (
              <option key={item.id} value={item.id}>
                {item.name}
              </option>
            ))}
          </select>
        </div>
      )}
    </div>
  );
};
export default LocationSelector;
const Labelspan = styled.span`
  color: #000;
  font-family: "Space Grotesk";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 3.5px;
`;
