import React from "react";
import styled from 'styled-components';
import { Dashboard } from "../components/dashboard";
import { Filter } from "../components/filter";

export const PrayerPartnerPage = () => {

    return(
        <Wrapper className="p-2">
            <h1>Prayer Partner Dashboard</h1>   
            <Filter page={"subscriberDashboard"} />
            <Dashboard/>  
        </Wrapper>
    )
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h1{
        color: #00323B;
    }
`;