import { InvoicePage } from './pages/invoice-page';
import { BlockCallerPage } from './pages/block-caller-page';
import { TestimonyPage } from './pages/testimony-page';
import { ProfilePage } from './pages/profile-page';
import { CallLogsPage } from './pages/call-logs-page';
import CallForm from './components/pastoral-care/pages/create-follow-up';
import NewFollowUp from './components/pastoral-care/pages/new-follow-up';
import OngoingFollowUp from './components/pastoral-care/pages/ongoing-follow-up';
import CompletedFollowUp from './components/pastoral-care/pages/completed-follow-up';
import AllFollowUp from './components/pastoral-care/pages/All-follow-up';
import CallLogs from './components/pastoral-care/pages/calllogs';
import MailingResources from './components/pastoral-care/pages/mailing-resources';
import ChurchLocation from './components/pastoral-care/pages/church-locations';
import DisplayFollowUp from './components/pastoral-care/pages/display-follow-up';
import { SubscribersPage } from './pages/subscribers-page';

export const routesConfig = [
    {
        path: '/home',
        element: <ProfilePage />,
        allowedRoles: ['admin', 'subscriber', 'super_admin', 'prayer_centre_admin', 'pastoral_care', 'prayer_centre_lead']
    },
    {
        path: '/blockcaller',
        element: <BlockCallerPage />,
        allowedRoles: ['prayer_centre_admin', 'pastoral_care', 'admin', 'super_admin', 'prayer_centre_lead']
    },
    {
        path: '/callform',
        element: <CallForm />,
        allowedRoles: ['pastoral_care', 'super_admin']
    },
    {
        path: '/churches',
        element: <ChurchLocation />,
        allowedRoles: ['pastoral_care', 'super_admin']
    },
    {
        path: '/followups',
        element: <AllFollowUp />,
        allowedRoles: ['pastoral_care', 'super_admin']
    },
    {
        path: '/followups/:followUpID',
        element: <DisplayFollowUp />,
        allowedRoles: ['pastoral_care', 'super_admin']
    },
    {
        path: '/followups/completed',
        element: <CompletedFollowUp />,
        allowedRoles: ['pastoral_care', 'super_admin']
    },
    {
        path: '/followups/new',
        element: <NewFollowUp />,
        allowedRoles: ['pastoral_care', 'super_admin']
    },
    {
        path: '/followups/ongoing',
        element: <OngoingFollowUp />,
        allowedRoles: ['pastoral_care', 'super_admin']
    },
    {
        path: '/invoices',
        element: <InvoicePage />,
        allowedRoles: ['subscriber', 'admin', 'super_admin']
    },
    {
        path: '/mailing',
        element: <MailingResources />,
        allowedRoles: ['pastoral_care', 'super_admin']
    },
    {
        path: '/pastoral-calllogs',
        element: <CallLogs />,
        allowedRoles: ['pastoral_care', 'super_admin']
    },
    {
        path: '/prayer-partner-calllogs',
        element: <CallLogsPage />,
        allowedRoles: ['prayer_centre_admin', 'super_admin', 'prayer_centre_lead']
    },
    {
        path: '/subscribers',
        element: <SubscribersPage />,
        allowedRoles: ['admin', 'super_admin']
    },
    {
        path: '/testimony',
        element: <TestimonyPage />,
        allowedRoles: ['prayer_centre_admin', 'super_admin', 'admin']
    }    
];
