import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components';
import { AddOrganization } from '../components/add-organization';
import CreateAccount from '../components/create-account';
import { OrganizationContext } from '../contexts/OrganizationContext';
import { sendMonthlyBill } from '../services/stripe-service';
import { Dashboard } from '../components/dashboard';
export const AdminPage = () => {
  const [show, setShow] = useState(false);
  const [showLock, setShowLock] = useState(false);
  const { getTotalMonthlyCalls, organizationNumbers, filterByArray, nameClicked } = useContext(OrganizationContext);

  const handleSendBill = async() =>{
    const result = await getTotalMonthlyCalls();
    console.log(result)
    sendMonthlyBill(result);
  }

  const isFirstOfMonth = () =>{    
    const date = new Date();
    const day = date.getDate();
    return !(day === 5);
  }

  return (
    <Wrapper>
      <div className="content-layout">
        <header className=" mb-3 text-center font-['Futura_PT'] bg-[#F1F1F1] rounded p-2 text-[#178B96]">
          <h1>Admin Page</h1>
        </header>
        <div className="content__body">
          <Wrapper className='row mb-5'>
            <div className='mt-4 flex justify-center text-white'>
              <button className="bg-[#FF7234] rounded p-2 mx-2" onClick={()=> {setShow(true)}}>ADD A NEW ORGANIZATION</button>
              <button className="bg-[#40D13D] rounded p-2 mx-2" onClick={()=> setShowLock(true)}>CREATE NEW USER ACCOUNT </button>
              <button disabled={isFirstOfMonth()} className={ isFirstOfMonth() ? "bg-[#178B96] disabled rounded mx-2 p-2 " : " bg-[#178B96] rounded mx-2 p-2"} 
                onClick={()=> handleSendBill()}>Send Monthly Bill
              </button>
            </div>
          </Wrapper>
        </div>
        <p className="px-4 text-start w-100" style={{ color: "#FF7234"}} >
          { filterByArray.length >0 ? 
          <> Showing data for {Object.values(organizationNumbers).length} {Object.values(organizationNumbers).length === 1 ? "organization" : "organizations"} </> 
          : nameClicked ? <>Showing Data for {nameClicked}</> : "Showing Data for Crossroads"}
        </p>
        <Dashboard />
      </div>
      <AddOrganization setShow={setShow} show={show} onHide={()=>setShow(false)} />
      <CreateAccount showLock={showLock} setShowLock={setShowLock} />
    </Wrapper>
  );
};
const Wrapper = styled.div`
    width: 100%;
`;
const Button = styled.button`
    border-radius: 8px;
    display:block;
    border: 0;
    width:30%;
    height: 120px;
    color: white;
    font-weight: 500;
`;
const ViewOptions = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    flex: 1;
    height: 85vh;
`;
const AddOrganizationButton = styled(Button)`
    background-color: #FF7234;
`;
const CreateAccountButton = styled(Button)`
    background-color: #40D13D;

`;

const MonthlyBill = styled.button`
    background-color: #178B96;
    color: white;
`;
const ClearSpan = styled.span`
    background-color: #FF7234;
    color: white;
    float: right;
    svg{
        display: inline;
    }
    cursor: pointer;
`;

const AdminButtons = styled.div`
    display: flex;
    justify-content: space-between;

    .disabled{
        background-color: #F1F1F1 ;
        color: rgb(45, 179, 192, 0.4);
    }
`;