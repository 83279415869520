import React, { useContext, useState } from "react";
import styled from "styled-components";
import { PrayerPartnerContext } from "../contexts/PrayerPartnerContext";
import { blockReasons } from "../assets/data";
import { ToastContainer } from 'react-toastify';
import { showErrorMessage, showSuccessMessage } from "../helpers/useToast";

export const BlockCallerPage = ( ) => {
    const { blockCaller }  = useContext(PrayerPartnerContext);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [blockReason, setBlockReason] = useState("");

    const handleSubmit = async(e) => {
        e.preventDefault();
        // Ensure both phone number and block reason are selected
        if (phoneNumber && blockReason) {
            let phone_number = "+" + phoneNumber;
            let block_reason = JSON.parse(blockReason)
          // Call the blockCaller API with the selected data
            const result =  await blockCaller({
                phone_number,
                blockdelay: block_reason.delay,
                blockreason: block_reason.reason,
            });
            if (result.data.success){
                showSuccessMessage(result.data.message);
            }
            else {
                showErrorMessage('Error occured');
            }
            // Reset the form
            setPhoneNumber("");
            setBlockReason("");
        } else {
            showErrorMessage('Please fill in all fields');
        }
    };
    return (
        <Wrapper>
            <ToastContainer/>
            <header className="mb-3">
                <h1>Block a caller Number</h1>
            </header>

            <BlockForm className="m-4 p-4 rounded" onSubmit={handleSubmit}>  
                <div>
                    <label htmlFor="phone_number" className="block text-sm font-medium text-gray-700 mb-2" >Enter Phone Number to block: </label>
                    <div >
                        <input
                        type="tel"
                        pattern="[0-9]{11}"
                        id="phone_number"
                        placeholder="12899229822"
                        name="phone_number"
                        maxLength={11}
                        minLength={11}
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        className="block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        required
                        />
                        <span className="absolute inset-y-0 right-3 flex items-center">
                        <svg className="w-5 h-5 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 10h18M3 14h18M7 18h10" />
                        </svg>
                        </span>
                    </div>
                    <small className="text-gray-500">Phone number should be 11 digits, e.g., 16476474456</small>

                  
                    {/* <input type="tel" pattern="[0-9]{10}"
                        id="phone_number" className="border"
                        value={phoneNumber} placeholder="12899229822" 
                        onChange={(e) => setPhoneNumber(e.target.value)}  required/>        */}
                </div>
                
                <div>
                    <label htmlFor="blockReason" className="block text-sm font-medium text-gray-700 mb-2 mt-4" >Reason For Block: </label>
                    <select className="form-select" aria-label="Reason For Block"  id="blockReason" value={blockReason}
                        onChange={(e)=> setBlockReason(e.target.value)}>
                            <option value="" disabled>Select a reason</option>
                            { 
                            blockReasons.map((blockReason) => {
                                return (
                                    <option key={blockReason.id+blockReason.reason} value={JSON.stringify(blockReason)}>{blockReason.reason}</option>
                                );
                            })
                            }
                    </select>  
                </div>

                <button type="submit" className="mt-4 px-4 py-2 rounded border">
                    Block this Number
                </button>
            </BlockForm>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    width: 100%;
    header {
        text-align: center;
        background-color: #F1F1F1;
        color: #178B96;
        font-family: Futura PT;
    }
`;

const BlockForm = styled.form`
    border: 1px solid #178B96;

    button{
        background-color: #FCD5C9;
        color: #EC3310;
    };
`;