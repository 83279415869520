import React, { useEffect, useContext, useState } from "react";
import { Link } from "react-router-dom";
import styled from 'styled-components';
import { OrganizationContext } from "../contexts/OrganizationContext";
import { Filter } from "../components/filter";
import { Dashboard } from "../components/dashboard";
export const SuperAdminPage = () => {

    const {organizationNumbers, filterByArray, nameClicked} = useContext(OrganizationContext);
    return(
        <Wrapper className="p-2 pt-0">
            <h1>Super Admin Dashboard</h1>
            <Filter page={"superAdminDashboard"} />
            <p className="px-4 text-start w-100" style={{ color: "#FF7234"}} >
                { filterByArray.length >0 ? 
                <> Showing data for {Object.values(organizationNumbers).length} {Object.values(organizationNumbers).length === 1 ? "organization" : "organizations"} </> 
                : nameClicked ? <>Showing Data for {nameClicked}</> : "Showing Data for Crossroads"}
            </p>
            <Dashboard/>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h1 {
        color: #00323B;
    }
    
`;