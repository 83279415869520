import React from "react";
import { FiFacebook, FiInstagram, FiLinkedin } from "react-icons/fi";
import { SlGlobe } from "react-icons/sl";

import styled from "styled-components";
export const PageFooter = () => {

  return (
    <Wrapper className="p-5 ml-6">
      <div style={{display:"flex"}}>
        <div>
          <h5>COMPANY</h5>
          <div style={{display:"flex"}}>

            <div style={{display:"flex", flexDirection:"column", marginRight: "2em"}} >
              <a style={{padding: "0.3em"}} target="_blank" href="https://anyonepray.com/about/crossroads/"> About </a>
              <a style={{padding: "0.3em"}} target="_blank" href="https://crossroads.ca/terms-of-service/"> Terms of Service </a>
              <a style={{padding: "0.3em"}} target="_blank" href="https://anyonepray.com/wp-content/uploads/2024/01/Platform-privacy-policy-v2.pdf"> Privacy Policy </a>
            </div>
          
            <div style={{display:"flex", flexDirection:"column", marginRight: "10em", marginLeft: "2em"}}>
              <a style={{padding: "0.3em"}} target="_blank" href="https://crossroads.ca/cookie-policy/"> Cookie Policy </a>
              <a style={{padding: "0.3em"}} target="_blank" href="https://crossroads.ca/sms-terms-conditions/"> SMS Terms of Service </a>
              <a style={{padding: "0.3em"}} target="_blank" href="https://crossroads.ca/news/crossroads-updates/"> Company Updates </a>
            </div>
          </div>
        </div>

        <div>
          <div>
            <h5 className="mb-0">ACCOUNT MANAGER EMAIL</h5>
            <a href="mailto:contact@anyonepray.com"> contact@anyonepray.com </a>
          </div>

          <div className="mt-3">
            <h5 className="mb-0">SEND SUPPORT REQUEST TO</h5>
            <a href="mailto:support@anyonepray.com"> support@anyonepray.com </a>
          </div>

          <div className="mt-3">
            <a href="https://drive.google.com/drive/folders/1l-tux9vHLhEJz6SXQ-amiJVFYLtvn8fh"  target="_blank"><h5 className="mb-0">SUBSCRIBER TOOLBOX</h5></a>
          </div>

          <div className="mt-3">
            <h5>CROSSROADS SWITCHBOARD</h5>
            <span style={{color: "#2DB3C0"}}>1-905-335-7100</span>
          </div>
        </div>
      </div>

      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center"}}>
        <CompanyName style={{color: "white" }}>© Crossroads Christian Communications Inc. </CompanyName>

        <div style={{display: "flex", justifyContent: "end"}}>
          <div style={{display: "flex", flexDirection: "column"}}>
            <CompanyLogo src={"https://brass-lynx-1229.twil.io/assets/crossroads_dark.png"} alt="Crossroads dark logo" />
            <div style={{display:"flex", justifyContent:"end", color: "white"}}> 
              <FiFacebook className="mx-2" size={"22"}/> <FiInstagram className="mx-2" size={"22"}/> 
              <FiLinkedin className="mx-2" size={"22"}/> <SlGlobe className="mx-2" size={"22"}/></div>
          </div>
        </div>
      </div>

    </Wrapper>
  );
};
const Wrapper = styled.footer`
 background-color: #00323B;
 display: flex;
 flex-direction: column;
 h5{
  font-family: "Futura PT";
  color: #C2EDF6;
 }
 a{
  color: #2DB3C0;
 }
`;


const CompanyName = styled.span`

`;

const CompanyLogo = styled.img`
  width: 300px; 
  filter:  brightness(0) invert(1);
  opacity: 0.8;

 `