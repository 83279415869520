import React, {useContext} from "react";
import { NavBar } from "./navigation/nav-bar";
import { PageFooter } from "./page-footer";
import SideMenu from "./side-menu";
import { ProfileContext } from "../contexts/ProfileContext";
export const PageLayout = ({ children }) => {

  const {worker, organizationImage} = useContext(ProfileContext);
  return (
    <div className="page-layout">
      {/* <NavBar /> */}
      { worker && <SideMenu userRole={worker.role} userName={worker.fullName} userImage={organizationImage} /> }
      <div className="page-layout__content p-12 m-4">{children}</div>
      <PageFooter />
    </div>
  );
};