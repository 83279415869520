import React, { useEffect, useContext, useState } from "react";
import styled from 'styled-components';
import { useNavigate } from "react-router-dom";
import { OrganizationContext } from "../contexts/OrganizationContext";
import { ProfileContext } from "../contexts/ProfileContext";
import { Dashboard } from "./dashboard";
import { MdClear } from "react-icons/md";
import { sendMonthlyBill } from "../services/stripe-service";
//the component that displays the different organizations and thier info

export const ViewOrganizations = () => {
    const {organizations, organizationNumbers, filterByArray, setFilterByArray, setFilterValueArray,
         showAllOrganizations, setShowAllOrganizations , getTotalMonthlyCalls, nameClicked, setNameClicked} = useContext(OrganizationContext);
    const {setOrganizationNumber, showReports, setShowReports, worker} = useContext(ProfileContext);
    const navigate = useNavigate();

    const handleNameClick = (selectedOrganization) =>{
        window.scrollTo({ top: 0, behavior: "smooth" });
        setFilterByArray([]);
        setFilterValueArray([]);
        setNameClicked(selectedOrganization.name);
        setOrganizationNumber(selectedOrganization.assignedNumber);
        navigate('/home');
    }
    useEffect(() =>{
        if (worker.role.includes('admin')){
            setShowAllOrganizations(true);
        }
    }, [worker])

    useEffect(()=>{
        setShowReports(false);
        if(filterByArray.length>0) {
            setOrganizationNumber(Object.values(organizationNumbers));
            setNameClicked("");
        }else if(filterByArray.length === 0 && !nameClicked){
             setNameClicked("");
             setOrganizationNumber("+18662734444");
       }
    }, [organizationNumbers])

    return (
        <>
         {organizations && 
            <Table className="p-2">
                <thead>
                    <tr>
                        <th>Organization Name</th>
                        <th>Organization Type</th>
                        <th>Organization Tier</th>
                        <th>Organization Toll Number</th>
                        <th>Organization Care Person</th>
                    </tr>
                </thead>
                <tbody>
                    {organizations.map((organization, index) => {
                        return (
                            <tr key={index}>
                                <NameTD onClick={()=>handleNameClick(organization)}>{organization.name}</NameTD>
                                <td>{(organization.type).charAt(0).toUpperCase() + (organization.type).slice(1)}</td>
                                <td>{(organization.tier).charAt(0).toUpperCase() + (organization.tier).slice(1)}</td>
                                <td>{organization.assignedNumber}</td>
                                <td>{organization.carePersonName}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </Table>

            // :
            //     <>
            //         <p className="px-4 text-start w-100" style={{ color: "#FF7234"}} >
            //             { filterByArray.length >0 ? 
            //             <> Showing data for {Object.values(organizationNumbers).length} {Object.values(organizationNumbers).length === 1 ? "organization" : "organizations"} </> 
            //             : nameClicked ? <>Showing Data for {nameClicked}</> : "Showing Data for Crossroads"}</p>
            //         <Dashboard/>
            //     </>
             }
        </>
        
    )
}

const DashboardLinks = styled.div`
    display: flex;
    flex-direction: row;
`;

const Table = styled.table`
    width: 100%;
    border: 1px solid #00323B;
    thead{
        flex-shrink: 0;
        border-bottom: 1px solid #00323B;
        background-color: #F1F1F1;
        color: #178B96;
        text-align: center;
    }
    tbody{
        text-align: center;
        tr{
            
        }
        td{
            padding: 0.5em;
            border-bottom: 1px solid #00323B;
            background-color: #fff;
            color: #178B96;
        }
    }

`;
const NameTD = styled.td`
    &:hover{
        text-decoration: underline;
        cursor: pointer
    }
`;  

