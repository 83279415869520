import React, { useState, useRef, useEffect, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { routesConfig } from '../routesConfig';
import { SquaresFour, Certificate, PhoneX, Notebook, HandHeart, VideoConference, Envelope,
   Church, HandsPraying, Invoice, BuildingOffice, CaretDown, SignOut, House } from "@phosphor-icons/react";
import { ProfileContext } from '../contexts/ProfileContext';

const SideMenu = ({ userRole, userName, userImage }) => {
    const [isOpen, setIsOpen] = useState(true);
    const [expandedMenus, setExpandedMenus] = useState({});
    const logo = "https://firebasestorage.googleapis.com/v0/b/anyonepray-dashboard.appspot.com/o/logos%2FCrossroads.png?alt=media&token=530408f4-a939-4368-8ad8-e42d6d925d01";
    const { handleShow } = useContext(ProfileContext);
    const location = useLocation();
    const activePath = location.pathname;
    const sidebarRef = useRef(null);
    const { logout } = useAuth0();

    // Filter routes based on user role
    const allowedRoutes = routesConfig.filter(route => 
      route.allowedRoles.includes(userRole)
    );
    const handleLogout = () => {
        logout({
          logoutParams: {
            returnTo: window.location.origin,
          },
        });
    };

    const getIcon = (path) => {
      switch (path) {
        case '/home':
          return <SquaresFour size={24} strokeWidth={1.8}/>;
        case '/subscribers':
          return <BuildingOffice size={24} strokeWidth={1.8}/>;
        case '/testimony':
          return <Certificate size={24} strokeWidth={1.8} />;
        case '/followups':
          return <Notebook size={24} strokeWidth={1.8}/>;
        case '/blockcaller':
          return <PhoneX size={24} strokeWidth={1.8}/>;
        case '/callform':
          return <VideoConference size={24} strokeWidth={1.8} />;
        case '/pastoral-calllogs':
          return <HandHeart size={24} strokeWidth={1.8} />;
        case '/mailing':
          return <Envelope size={24} strokeWidth={1.8} />;
        case '/churches':
          return <Church size={24} strokeWidth={1.8} />;
        case '/prayer-partner-calllogs':
          return <HandsPraying size={24} strokeWidth={1.8}/>;
        case '/invoices':
          return <Invoice size={24} strokeWidth={1.8}/>;
        default:
          return <House size={24} strokeWidth={1.8}/>;
      }
    };
    const toggleSidebar = () => setIsOpen(!isOpen);

    const toggleSubmenu = (path) => {
      setExpandedMenus(prev => ({
        ...prev,
        [path]: !prev[path]
      }));
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };
    
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const renderMenuItem = (route) => {
      const isSubmenu = route.path.includes('/followups');
      const subRoutes = isSubmenu ? allowedRoutes.filter(r => r.path.startsWith(route.path) && r.path !== route.path) : [];
  
      return (
        <li key={route.path}>
          {isSubmenu ? (
            <div>
              <button
                onClick={() => toggleSubmenu(route.path)}
                className={`flex items-center w-full text-left text-gray-500 hover:bg-gray-100 rounded-md p-2 ${
                  activePath.startsWith(route.path) ? 'bg-blue-100' : ''
                }`}
              >
                <span className={isOpen ? '' : 'mx-auto'}>{getIcon(route.path)}</span>
                {isOpen && (
                  <>
                    <span className="ml-3 ">FOLLOW-UPS</span>
                    <CaretDown size={24} className={`ml-auto transition-transform ${expandedMenus[route.path] ? 'rotate-180' : ''}`} />
                  </>
                )}
              </button>
              {isOpen && expandedMenus[route.path] && (
                <ul className=" mt-2 space-y-2">
                  {subRoutes
                  .filter((route) => !route.path.includes(':'))
                  .map(subRoute => (
                    <li key={subRoute.path}>
                      <Link
                        to={subRoute.path}
                        className={`flex items-center text-gray-500 hover:bg-[#BFE9EE] hover:text-gray-700 rounded-md p-1 tracking-wide
                          ${ activePath === route.path ? 'bg-[#F3F9FA] text-black font-semibold' : ''
                        }`}
                        style={{ textDecoration: 'none' }}
                      >
                        <span className={isOpen ? '' : 'mx-auto'}>{getIcon(route.path)}</span>

                        {isOpen && 
                          <span className="ml-3">
                            {subRoute.path.split('/').pop().replace(/-/g, ' ').toUpperCase()}
                          </span>
                        }
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          ) : (
            <Link
              to={route.path}
              className={`flex items-center text-gray-500 hover:bg-[#BFE9EE] hover:text-gray-700 rounded-md p-2 tracking-wide
                ${ activePath === route.path ? 'bg-[#F3F9FA] text-black font-semibold' : ''
              }`}
              style={{ textDecoration: 'none' }}
            >
              <span className={isOpen ? '' : 'm-2'}>{getIcon(route.path)}</span>
              {isOpen && (
                <span className="ml-3">
                  {route.path.split('/').pop().replace(/-/g, ' ').toUpperCase()}
                </span>
              )}
            </Link>
          )}
        </li>
      );
    };
  
    return (
      <div ref={sidebarRef} 
        className={`fixed left-0 top-0 h-full bg-white transition-all z-50 duration-300 ease-in-out ${isOpen ? 'w-64' : 'w-16'} shadow-lg flex flex-col`}>
        <div className={isOpen ? 'p-4 ' : 'py-4 ' + 'flex justify-center items-center cursor-pointer'} onClick={toggleSidebar}>
            {isOpen ? (
              <img src="https://brass-lynx-1229.twil.io/assets/A1P_Logo_ByCCCI-Dark.png" alt="A1P Logo" className="h-8" />
            ) : (
              <img src={require("../assets/images/a1pLogo.png")} alt="a1p Logo" className="h-12" />
            )}
        </div>
  
        <nav className="flex-grow overflow-y-auto font-['Futura PT]">
          <ul className= {isOpen ? "space-y-2 p-4" : 'p-0 m-1'}>
            {allowedRoutes
            .filter((route) => !route.path.includes('/followups') || route.path === '/followups').map(renderMenuItem)
            }
          </ul>
        </nav>
  
        <div className="border-t border-gray-200 ">
          <div className={isOpen ? "flex items-center mb-3 justify-center cursor-pointer w-full hover:bg-[#BFE9EE] rounded p-3" : "flex justify-center items-center mb-3 rounded cursor-pointer hover:bg-[#BFE9EE]"} onClick={handleShow}>
            <div className="w-10 h-10 rounded-full flex items-center justify-center ">
              {userImage ?
                <img src={userImage} alt="Account Image Icon"  />
                :
                <span className="text-lg font-semibold bg-gray-300 rounded-full p-1 ">
                  {userName.split(' ').map(n => n[0]).join('').toUpperCase()}
                </span>
              }
            </div>
            
            {isOpen &&(
              <div>
                <p className="font-semibold text-sm m-0 pl-1">{userName}</p>
                <p className="text-xs text-gray-500 m-0 pl-1">{userRole?.replace('_', ' ').toUpperCase()}</p>
              </div>
            )}
          </div>
          <button
            onClick={handleLogout}
            className={`flex items-center justify-center w-full text-red-600 hover:bg-red-100 rounded-md p-2 ${isOpen ? '' : 'px-0'}`}
          >
            <SignOut size={26} />
            {isOpen && <span className="ml-2">Logout</span>}
          </button>
        </div>
      </div>
    );
};

export default SideMenu;

