import React, { useContext, useState } from "react";
import styled from "styled-components";
import { PrayerPartnerContext } from "../contexts/PrayerPartnerContext";
import { useQuery } from "@tanstack/react-query";
import { Pagination } from "../components/pagination";
import { PageLoader } from "../components/page-loader";
import { NotFoundPage } from "./not-found-page";
export const TestimonyPage = ( ) => {
    const { fetchTestimony }  = useContext(PrayerPartnerContext);
    const [currentPage, setCurrentPage] = useState(1);
    const getTestimony = () => {
        window.scrollTo({top: 0});
        return fetchTestimony(currentPage)

    }
    const { isPending, status,  isError, data, error } = useQuery({
        queryKey: ['testimonies', currentPage],
        queryFn: getTestimony
    })

    if (isPending) return <PageLoader />;
    if (isError) return <NotFoundPage/>;

    const testimonies = data.data
    const totalCount = data.total_count

    return (
        <div className="m-3">
            <header className="mb-3 text-center font-['Futura_PT'] bg-[#F1F1F1] rounded p-2 text-[#178B96]">
                <h1>Testimonials</h1>
            </header>

            { testimonies && 
                <div className="p-2 m-2 px-4">
                    {testimonies.map((testimony, index) => {
                        return (
                            <div className="border-b-2 border-gray-500" key={index}>
                                <p className="pt-2 px-4 text-center">{testimony.notes}</p>
                                <span className="px-3 flex justify-end">{testimony.caller_first_name} {testimony.caller_last_name}</span>
                            </div>
                        );
                    })
                    }
                </div>
            }
            <Pagination totalCount={totalCount} currentPage={currentPage} setCurrentPage={setCurrentPage} />
        </div>
    );
}

const TestimonyItem = styled.div`
    border-bottom: 0.5px solid black;
    p {
        text-align: center;
    }

`;