import { Filter } from "../components/filter";
import { ViewOrganizations } from "../components/view-organizations";


export const SubscribersPage = () => {
    return (
        <div className="m-3">
             <header className=" mb-3 text-center font-['Futura_PT'] bg-[#F1F1F1] rounded p-2 text-[#178B96]">
                <h1>Subscribers</h1>
            </header>
            <Filter page={"superAdminDashboard"} />
            <ViewOrganizations/>
        </div>
    );
}
