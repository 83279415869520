import { PageLayout } from "./components/page-layout";
import { PageLayout as PastoralPageLayout } from "./components/pastoral-care/page-layout";

const LayoutWrapper = ({ children, role }) => {
    if (role === "pastoral_care") {
        return <PastoralPageLayout>{children}</PastoralPageLayout>;
    } if (role)  {
        return <PageLayout>{children}</PageLayout>;
    }
    // Add more layouts if needed
   // return <>{children}</>;
};

export default LayoutWrapper;