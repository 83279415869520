import React, { useContext, useState, useRef, useEffect } from "react";
import { ProfileContext } from "../contexts/ProfileContext";
import styled from 'styled-components';
import "../styles/Dashboard.css"
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title, PointElement, LineElement } from 'chart.js';
import { Bar, Doughnut, Line, Pie } from 'react-chartjs-2';
import { LoadingSpinner } from "../components/loading-spinner";
import { useReactToPrint } from 'react-to-print';
import { saveAs } from 'file-saver';
import Papa from 'papaparse';
import { useQuery } from "@tanstack/react-query"
import { DashboardStats } from "./dashboard-stats";
ChartJS.register(ArcElement, Tooltip, Title, Legend, CategoryScale, LinearScale, BarElement, PointElement, LineElement);

//the dashboard component that illustrates raw data into pie and bar charts
export const Dashboard = () => {
    const [exportOption, setExportOption] = useState("");
    const componentToPrint = useRef();

    const {  setShowModel, showModel, organization, setShowReports, dateRange, generateReportsCharts, evenlyDistribute, worker, getOrganizationsReport, organizationNumber }  = useContext(ProfileContext);
    const organizationName = worker?.organizationName
    const { isPending, status,  isError, data, error } = useQuery({
        queryKey: ['report', {organizationNumber, showModel, dateRange }],
        queryFn: getOrganizationsReport,
        enabled: !!organizationName ,
    })

    useEffect(() =>{
        setShowReports(true);
    },[])

    const handleSwitch = (e) => {
        setShowModel(e.target.value)
    }

    const handleExportOption = (e)=> {
        setExportOption(e.target.value)

        if (e.target.value === "print") {
            handlePrint()
        }
        else if(e.target.value === "export") {
            handleExport()
        }
    }
    const handlePrint = useReactToPrint({
        content: () => componentToPrint.current,
        documentTitle: 'Anyone Pray Dashboard',
        onAfterPrint: () => console.log('Printed PDF successfully!'),
    });

    const getLabelCounts = (data, labelKey) => {
        data = data && data.toString().split(",")
        const elementCounts = {};
        data.forEach(element => {
            element = element.trim();
            elementCounts[element] = (elementCounts[element] || 0) + 1;
        });
    
        return Object.keys(elementCounts).map(label => ({
            [labelKey]: label,
            total: elementCounts[label]
        }));
    };

    if (isPending) return <LoadingSpinner/>
    if (isError) return <p>{JSON.stringify(error)}</p>

    const [ dispositionData, callTypeData, callTypeBreakdownData, ageRangeData, genderData, digitalResourcesData, physicalResourcesData] = generateReportsCharts(data.details, data.stats.total_calls);
    const handleExport = () => {
        // Combine data arrays if necessary
        const csvData1 = Papa.unparse(getLabelCounts(data.details.call_type_report, "call_types"), { header: true });
        const csvData2 = Papa.unparse(evenlyDistribute(data.details.disposition_report, data.stats.total_calls), { header: true });
        const csvData3 = Papa.unparse(data.details.gender_report, { header: true });
        const csvData4 = Papa.unparse(data.details.age_range_report, {header: true });
        const csvData5 = Papa.unparse(getLabelCounts(data.details.digital_resource_report, "digital_resources"), { header: true });

        // Combine the two CSV strings
        const combinedCSV = `${csvData1}\n\n${csvData2}\n\n${csvData3}\n\n${csvData4}\n\n${csvData5}\n\n`;

        // Create a Blob from the combined CSV string
        const blob = new Blob([combinedCSV], { type: 'text/csv;charset=utf-8;' });

        // // Use file-saver to trigger the download
        saveAs(blob, 'AnyonePrayDashboard.csv');
    };

    return (
        <Wrapper>
            <ButtonWrapper>
                <ToggleButtonWrapper className={ dateRange ? " disabled m-0": "m-0"}>
                    <div className="container">
                        <div className="switches-container">
                        <input type="radio" id="switchAll" name="switchData" value="all" checked={showModel === "all"} onChange={handleSwitch} />
                        <input type="radio" id="switchMonthly" name="switchData" value="monthly" checked={showModel === "monthly"} onChange={handleSwitch} />
                        <label htmlFor="switchAll">ALL DATA</label>
                        <label htmlFor="switchMonthly">CURRENT MONTH</label>
                        <div className="switch-wrapper">
                            <div className="switch">
                                <div>ALL DATA</div>
                                <div>CURRENT MONTH</div>
                            </div>
                        </div>
                        </div>
                    </div>
                </ToggleButtonWrapper>
            
                <ExportOption onChange={(e)=>{handleExportOption(e)}} 
                    className="rounded mr-2 bg-[#FAF8F8] hover:bg-[#ADCFD4] border-1 border-[#106271]  text-[#106271]"
                    value={exportOption}>
                    <option value="">Export Options</option>
                    <option value="print"> Print PDF</option>
                    <option value="export">Export as Excel Sheet</option>
                </ExportOption>
            </ButtonWrapper>
            
            <ChartsWrapper className="p-3" ref={componentToPrint}> 
                {organizationName === "Crossroads" && <DashboardStats />}
                <DarkCard className='col-md-6 col-sm-10 mb-5 p-2'>
                    <DarkCardValue>
                    {(data.stats.total_calls).toLocaleString("en-US")}
                    
                    </DarkCardValue>
                    <DarkCardLabel>total calls received</DarkCardLabel>
                </DarkCard>

                <Charts className="row m-3">
                    <div className='col-md-10 col-sm-12'>
                    <Bar className='col' data={callTypeData} />
                    </div>
                    { organization && organization.prayerPartnersCount &&
                    <CardContainer className='col-md-4 col-sm-10'>
                        <LightCard className='p-5'>
                            <LightCardValue>
                                {(organization.prayerPartnersCount)?.toLocaleString("en-US")}
                            </LightCardValue>
                            <LightCardLabel># of volunteers onboarded</LightCardLabel>
                        </LightCard>
                    </CardContainer>
                    }
                </Charts>

                <Charts className="row mb-5">
                    {
                        callTypeBreakdownData.map((breakdown, index) =>{
                           return( <div className="col-md-6" key={index}>
                                <Bar data={breakdown} 
                                    options={{maintainAspectRatio: true, plugins: {
                                    title: {display: true, position: 'top',font:{size: 16}},
                                    legend: {labels: {font: {size: 14 }}},}
                                }}
                                /> 
                            </div>)
                        })
                    }
                </Charts>

                <Charts className="row">
                    <div className="col-md-6">
                    <Doughnut data={dispositionData} 
                        options={{maintainAspectRatio: true, plugins: {
                            title: {display: true, text: "Disposition of Calls", position: 'top',font:{size: 16}},
                            legend: {position: 'right', labels: {font: {size: 14 }}},}
                        }}/> 

                    </div>
                    <div className="col-md-6" > 
                        <Doughnut data={genderData}  
                            options={{ plugins: { title: {display: true,  text: "Gender of Callers", position: 'top', font:{size: 16}},
                                legend: {  position: 'right', labels: {font: {size: 14}}},}
                            }}
                        />
                    </div>
                </Charts>

                <Charts className="row">
                    <div className='col-md-6 col-sm-12'>
                        <Line className='col' data={ageRangeData} />
                    </div>
                    <CardContainer className='col-md-4 col-sm-10'>
                        <LightCard className="p-4">
                            <LightCardValue>
                            { Array.isArray(data.stats.total_time_in_secs) ? (Math.floor(parseInt(data.stats.total_time_in_secs)/3600)).toLocaleString("en-US"):
                                (Math.floor(parseInt(data.stats.total_time_in_secs)/3600)) > 0 ? (Math.floor(parseInt(data.stats.total_time_in_secs)/3600)).toLocaleString("en-US") +"hrs"
                                : '< 1 hr'
                            } 
                            </LightCardValue>
                            <LightCardLabel>total call duration</LightCardLabel>
                        </LightCard>
                    </CardContainer>
                    
                </Charts>

                <Charts className="row">
                    <div className='col-md-6 col-sm-12 '>
                        <Doughnut data={digitalResourcesData} 
                        options={{ plugins: {
                            title: {display: true, text: "Digital Resource Reports", position: 'top', font:{size: 16}},
                            legend: { position: 'right', labels: { font: {size: 10}}},}
                        }}/>
                    </div>
                    {/* Check if there are physical resources sent| subscribers cant see physical resources */}
                    {physicalResourcesData.labels[0] !== '' && worker.role !== "subscriber" &&
                    <div className='mt-4 col-md-6 col-sm-12'>
                        <Doughnut data={physicalResourcesData} 
                        options={{ plugins: {
                            title: { display: true, text: "Physical Resource Reports", position: 'top',
                            font:{size: 16}},legend: {position: 'right', labels: {font: {size: 10}}},}
                       }}/> 
                    </div>
                    }
                </Charts>
            </ChartsWrapper>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    width: 100%;   
`;

const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items:center;
`;
const ExportOption = styled.select`
    height: 40px;
`;
const ToggleButtonWrapper = styled.div`
    width: 350px;
    font-size: 10pt;

    &.disabled{
        pointer-events: none;
        opacity: 0.6;
    }
`;

const ChartsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;
const Charts = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;

    justify-content: space-around;
    div{
      display: flex;
      align-items: center;
      justify-content: center;
    }
`;

const DarkCard = styled.div`
    background-color: #FCD5C9;
    box-shadow: 10px 10px 5px whitesmoke;
    border-radius: 30px;
    text-align: center;
`;
const CardContainer = styled.div`
    display:flex;
    align-items: center;
  justify-content: center;
`;

const DarkCardValue = styled.div`
    color: #EC3310;
    display: block;
    font-size: 5em;
    font-weight: 800;
`;

const DarkCardLabel = styled.div`
    vertical-align: bottom;
    color: #FF7234;
    text-transform: uppercase;

`;

const LightCard = styled.div`
    display: flex;
    flex-direction: column;
    
    background-color: #D9EEF0;
    box-shadow: 10px 10px 5px whitesmoke;
    border-radius: 30px;
    
`;
const LightCardValue = styled.div`
    color: #178B96;
    display: block;
    font-size: 5em;
    font-weight: 800;
`;

const LightCardLabel = styled.div`
    vertical-align: bottom;
    color: #2DB3C0;
    text-transform: uppercase;

`;